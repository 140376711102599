<template>
  <div>
    <b-modal
      id="modal-input-alergi"
      size="lg"
      centered
      title="Tambah Data Master Alergi"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Alergi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_alergi')"
            type="text"
            v-model="$v.is_data.nama_alergi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jenis Alergi <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="kd_tkp"
            v-model="$v.is_data.ms_jenis_alergi_id.$model"
            :options="listJenisAlergi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_jenis_alergi"
            track-by="ms_jenis_alergi_id"
            placeholder="-- Pilih Jenis Alergi --"
            size="sm"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Komposisi
          </template>
          <Multiselect
            id="kd_tkp"
            v-model="$v.is_data.ms_komposisi_barang_id.$model"
            :options="listKomposisiBarang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_komposisi"
            track-by="ms_komposisi_barang_id"
            placeholder="-- Pilih Komposisi --"
            size="sm"
          ></Multiselect>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-alergi')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props:[
    'listJenisAlergi',
    'listKomposisiBarang',
  ],
  data() {
    return {
      is_data: {
        nama_alergi: "",
        ms_jenis_alergi_id: '',
        ms_komposisi_barang_id: '',
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_alergi: { required },
      ms_jenis_alergi_id: { required },
      ms_komposisi_barang_id: {  },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/ms_alergi/register", {
          ...vm.is_data,
          ms_jenis_alergi_id: vm.is_data.ms_jenis_alergi_id ? vm.is_data.ms_jenis_alergi_id.ms_jenis_alergi_id : null,
          ms_komposisi_barang_id: vm.is_data.ms_komposisi_barang_id ? vm.is_data.ms_komposisi_barang_id.ms_komposisi_barang_id : null,
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER ALERGI",
              showing: true,
            });
            this.$bvModal.hide("modal-input-alergi");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_alergi: "",
        ms_jenis_alergi_id: '',
        ms_komposisi_barang_id: '',
      };
    },
  },
};
</script>
